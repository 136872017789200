import React from 'react'
import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'

export default ({ location }) => (
    <Layout>
        <SEO
            pagetitle="ページが見つかりません" pagepath={location.pathname}
        />
        <main className="Page404">
            <h1 className="Page404__title">お探しのページが見つかりませんでした</h1>
        </main>
    </Layout>
)